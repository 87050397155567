<template>
  <div>
    <v-slide-y-transition mode="out-in">
      <v-data-table
        v-if="!backItems.length"
        :headers="headers"
        :items="items"
        :page.sync="page"
        :itemsPerPage="itemsPerPage"
        :server-items-length="totalDesserts"
        :loading="loading"
        @page-count="pageLength = $event"
        hide-default-footer
        disable-sort
        key="main"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="onClickAction(item)" v-bind="attrs" v-on="on">
                mdi-file
              </v-icon>
            </template>
            <span>查看访视记录</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-row no-gutters dense align="center" class="pt-2">
            <v-spacer />
            <v-col cols="auto">
              <v-pagination
                circle
                v-model="page"
                :length="pageLength"
                total-visible="10"
                @input="changePage"
              />
            </v-col>
            <v-col
              cols="auto"
              class="text-center px-4"
              style="font-size: 16px; line-height: 40px;"
            >
              <span>共{{ totalDesserts }}条</span>
            </v-col>
            <v-col cols="auto" class="px-4">
              <v-select
                dense
                v-model="itemsPerPage"
                :items="pageSize"
                @change="changePageSize"
                hide-details="auto"
                class="pb-2"
                style="width: 100px"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <v-data-table
        v-else
        :headers="backHeaders"
        :items="backItems"
        hide-default-footer
        disable-pagination
        disable-sort
        key="sub"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-icon @click="onClickAction2(item)"> mdi-file </v-icon>
          <v-icon @click="onClear" color="red" class="ml-2">mdi-close</v-icon>
        </template>
        <template v-slot:footer>
          <v-divider />
        </template>
      </v-data-table>
    </v-slide-y-transition>
    <v-dialog v-model="vrDialog">
      <v-card>
        <v-data-table
          :headers="vrHeaders"
          :items="vrItems"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <template v-slot:[`item.B`]="{ item }">
            <v-icon color="green" v-if="item.B === '1'"> mdi-check </v-icon>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              :color="item.B === '1' ? 'green' : ''"
              @click="onClickAction3(item)"
            >
              mdi-file
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Paients",
  props: {
    callBack: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      vrDialog: false,
      totalDesserts: 0,
      items: [],
      backItems: [],
      loading: false,
      page: 1,
      itemsPerPage: 10,
      searchParam: {},
      selection: undefined,
      headers: [
        { text: "姓名", align: "center", value: "CNAME" },
        { text: "公司", align: "center", value: "COMPANYNAME" },
        { text: "性别", align: "center", value: "CSEX" },
        { text: "生日", align: "center", value: "CBIRTHDAY" },
        { text: "", value: "action", align: "center", width: "120" },
      ],
      backHeaders: [
        { text: "姓名", align: "center", value: "CNAME" },
        { text: "公司", align: "center", value: "COMPANYNAME" },
        { text: "性别", align: "center", value: "CSEX" },
        { text: "生日", align: "center", value: "CBIRTHDAY" },
        { text: "名称", align: "center", value: "A" },
        { text: "", value: "action", align: "center", width: "120" },
      ],
      pageSize: [
        { text: "5条/页", value: 5 },
        { text: "10条/页", value: 10 },
        { text: "20条/页", value: 20 },
      ],
      vrHeaders: [
        { text: "名称", align: "center", value: "A" },
        { text: "状态", align: "center", value: "B" },
        { text: "计划日期", align: "center", value: "C" },
        { text: "完成日期", align: "center", value: "D" },
        { text: "", value: "action", align: "center", width: "120" },
      ],
      vrItems: [
        { A: "访视一", B: "1", C: "2021-05-21", D: "2021-06-21" },
        { A: "访视二", B: "1", C: "2021-05-21", D: "2021-06-21" },
        { A: "访视三", B: "1", C: "2021-05-21", D: "2021-06-21" },
        { A: "访视四", B: "0", C: "2021-05-21", D: "2021-06-21" },
        { A: "访视五", B: "0", C: "2021-05-21", D: "2021-06-21" },
        { A: "访视六", B: "0", C: "2021-05-21", D: "2021-06-21" },
      ],
    };
  },
  mounted() {
    this.showPage();
  },
  computed: {},
  methods: {
    onClickAction(item) {
      this.selection = item;
      this.vrDialog = true;
    },
    onClickAction2() {
      this.vrDialog = true;
    },
    onClickAction3(item) {
      this.vrDialog = false;
      this.backItems = [];
      let temp = { ...item, ...this.selection };
      this.backItems.push(temp);
      this.callBack(temp);
    },
    onClear() {
      this.backItems = [];
      this.callBack(undefined);
    },
    showPage() {
      this.loading = true;
      this.$api
        .postGet_GroupUser({
          page_number: this.page,
          page_size: this.itemsPerPage,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.totalDesserts = data.total;
            this.items = JSON.parse(data.data || "[]");
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePage(index) {
      this.page = index;
      this.showPage();
    },
    changePageSize(size) {
      this.itemsPerPage = size;
      this.showPage();
    },
  },
};
</script>
