var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.backItems.length)?_c('v-data-table',{key:"main",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"hide-default-footer":"","disable-sort":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageLength = $event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.onClickAction(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file ")])]}}],null,true)},[_c('span',[_vm._v("查看访视记录")])])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","dense":"","align":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{attrs:{"circle":"","length":_vm.pageLength,"total-visible":"10"},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{staticClass:"text-center px-4",staticStyle:{"font-size":"16px","line-height":"40px"},attrs:{"cols":"auto"}},[_c('span',[_vm._v("共"+_vm._s(_vm.totalDesserts)+"条")])]),_c('v-col',{staticClass:"px-4",attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"pb-2",staticStyle:{"width":"100px"},attrs:{"dense":"","items":_vm.pageSize,"hide-details":"auto"},on:{"change":_vm.changePageSize},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)]},proxy:true}],null,true)}):_c('v-data-table',{key:"sub",attrs:{"headers":_vm.backHeaders,"items":_vm.backItems,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.onClickAction2(item)}}},[_vm._v(" mdi-file ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red"},on:{"click":_vm.onClear}},[_vm._v("mdi-close")])]}},{key:"footer",fn:function(){return [_c('v-divider')]},proxy:true}],null,true)})],1),_c('v-dialog',{model:{value:(_vm.vrDialog),callback:function ($$v) {_vm.vrDialog=$$v},expression:"vrDialog"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.vrHeaders,"items":_vm.vrItems,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.B",fn:function(ref){
var item = ref.item;
return [(item.B === '1')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.B === '1' ? 'green' : ''},on:{"click":function($event){return _vm.onClickAction3(item)}}},[_vm._v(" mdi-file ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }