<template>
  <div>
    <v-card class="pa-4">
      <Paients :callBack="paientsCallback" />
    </v-card>
    <v-card class="pa-4 mt-6" v-if="vrItem">
      1
    </v-card>
  </div>
</template>
<script>
import Paients from "./Paients.vue";
export default {
  name: "VisitingResearch",
  components: { Paients },
  data: () => ({
    vrItem: undefined,
  }),
  methods: {
    paientsCallback(item) {
      this.vrItem = item;
    },
  },
};
</script>
<style scoped></style>
